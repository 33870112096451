import type {
  ProductSmartFilterDetail,
  ProductSmartFilterPagedResult,
  SlugTranslation,
} from '~/types/api'
import { NuxtLocale } from '~/constants/locales'

export function createProductSmartFiltersRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const { $i18n } = useNuxtApp()
  const { locale } = $i18n

  const DOMAIN = 'product-smart-filters'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        filter: APIFilters.makeFilter(getBaseFilter(locale.value, true)),
        sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
      }
    }
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params,
    })
  }

  async function getAllPagination(params: {
    page: number
    itemsPerPage: number
    filter?: string
    sort?: string
  }) {
    if (!params.filter) {
      params.filter = APIFilters.makeFilter(getBaseFilter(locale.value, true))
    }
    if (!params.sort) {
      params.sort = APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' })
    }
    return get<ProductSmartFilterPagedResult>([DOMAIN], {
      params,
    })
  }

  async function getBySlug(slug: string) {
    const params = {
      language: convertNuxtLocale(locale.value as NuxtLocale),
    }
    return get<ProductSmartFilterDetail>([DOMAIN, slug], {
      params,
    })
  }

  async function translateSlug(slug: string) {
    const params = {
      language: convertNuxtLocale(locale.value as NuxtLocale),
    }
    return get<SlugTranslation>([DOMAIN, slug, 'translate'], {
      params,
    })
  }

  return {
    getAll,
    getAllPagination,
    getBySlug,
    translateSlug,
  }
}
